import { React, CenterWrapper, View, Text, SEO, Page, Image } from 'lib'
import { Theme, HTMLStylesDark } from 'app'
import { PageTitle } from 'components'
import { SpeedIcon, TrailIcon, HandIcon, NoSignalIcon } from 'assets/icons'
import { textObject } from '_app/stylesheets/TextStyles'
import TriggersImage from '../../assets/triggers.png'
const header = `How Becon's Safety Triggers Work`

function TriggersPage(props) {
  const isMobile = Theme.hooks.down('mid')

  return (
    <Page whiteBackground>
      <SEO doNotTrack title={header} description={'In order to enjoy as smooth, safe and accurate an experience as possible when using Becon, it is useful to know how our Safety Triggers work.'}/>
      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.content}>
        <PageTitle location='Guidance' title={header}/>

        <View variant={'flex column'} style={styles.contentWrapper}>
          <Text variant={'p2'}>
            To ensure you have the best possible experience when using Becon, it is helpful to understand how the technology in your smart safety app works.
            <br/><br/>
            Becon’s Safety Triggers are designed to detect unusual changes in your speed, motion, or location during your chosen activity or journey type, which may indicate that you’re in need of assistance.
            <br/><br/>
            Becon+ users can fully customise the app’s safety settings and enable/disable Safety Triggers if they wish to, but these instructions below presume that all are enabled for a regular journey.
          </Text>
          <View variant={'flex column'}>
            <Text variant={'h4'} text={'If, During Your Journey Or Activity...'}/>
            <View variant={'marginTop:6'} style={styles.iconsContainer}>
              <View style={styles.iconWrapper}>
                <HandIcon size={70} color={Theme.colors.grad1}/>
                <Text variant={'p3 inline marginTop:2'}>Becon detects no movement from your device for more than 2 minutes, then the <strong>Stopped Moving</strong> Safety Trigger will be activated.</Text>
              </View>
              <View style={styles.iconWrapper}>
                <SpeedIcon size={70} color={Theme.colors.grad1}/>
                <Text variant={'p3 inline marginTop:2'}>...your speed elevates significantly above  the normal threshold for your journey type then the <strong>High Speed</strong> Safety Trigger will be activated.</Text>
              </View>
              <View style={styles.iconWrapper}>
                <TrailIcon size={70} color={Theme.colors.grad1}/>
                <Text variant={'p3 inline marginTop:2'}>...your device location deviates by more than 50m from the route displayed within the Becon app, then the <strong>Off Route</strong> Safety Trigger will be activated.</Text>
              </View>
            </View>
          </View>
          <View style={styles.separator}/>
          <View variant={'flex fullWidth'} style={styles.imageWrapperContainer}>
            <Image source={TriggersImage} alt='trigger' style={isMobile ? styles.imageWrapperMobile : styles.imageWrapper }/>
            <View variant={'flex column'}>
              <Text variant={'p2 marginBottom:4'}>When a Safety Trigger is activated, a timed and security notification will appear on your device, like the example screen on the left here.</Text>

              <Text variant={'p2 marginBottom:4'}>If you are safe, then you have until the end of the timer to tap ‘I AM SAFE’, which will dismiss the notification and no alerts will be sent.</Text>

              <Text variant={'p2 marginBottom:4'}>If assistance is needed and you are able to, you can skip the countdown and send SMS alerts immediately by pressing the ‘ALERT CONTACTS NOW’ button.</Text>

              <Text variant={'p2 marginBottom:4'}>If you are unable to respond to confirm your safety by the end of the timer, then alerts will be sent to your Key Contacts automatically. An example of the SMS alert they will receive is shown here on the right.</Text>
            </View>
          </View>

          <View variant={'flex column'}>
            <Text variant={'h4'} text={'What If My Phone Is Damaged Or Turned Off While Becon Is Activated?'}/>

            <View variant={'flex marginTop:2'} style={styles.disconnectedWrapper}>
              <View variant={'flex column'} style={{ flex: 2 }}>
                <Text variant={'p2 inline marginBottom:4'}>To protect you against <strong>unwanted deactivation</strong> of Becon for any reason during your journey or activity, we built a fail-safe Safety Trigger -  <strong>Disconnected</strong>. </Text>

                <Text variant={'p2 inline marginBottom:4'}>The <strong>Disconnected</strong> Safety Trigger will be activated, and SMS alerts sent automatically to your Key Contacts, if Becon loses connection to your device completely for an extended period of time. The default setting is 45 minutes. </Text>

                <Text variant={'p2 inline marginBottom:4'}>Due to the parameters of this Safety Trigger, we recommend that you ensure your device has enough battery power to last until you would expect to reach your destination, and that you don’t activate Becon for journeys in areas without mobile data coverage.</Text>
              </View>
              <View variant={'flex column alignCenter justifyCenter'} style={{ flex: 1 }}>
                <NoSignalIcon size={80} color={Theme.colors.grad1}/>
                <Text variant={'h4 marginTop:3'} text={'Disconnected'}/>
              </View>
            </View>
          </View>
        </View>
      </CenterWrapper>
    </Page>
  )
}


const styles = {
  wrapper: {
    // minHeight: '100vh',
    ...Theme.center,
    ...Theme.flex,
    flexDirection: 'column',
    marginTop: Theme.spacing(20),
    marginBottom: Theme.spacing(8),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(12),
      marginBottom: Theme.spacing(5),
    },
  },
  content: {
    ...HTMLStylesDark.plainText,
    display: 'flex',
    width: 'auto',
    maxWidth: Theme.values.maxTextContentWidth,
    flexDirection: 'column',
    '& p': {
      ...textObject.p2,
    },
  },
  contentWrapper: {
    marginTop: Theme.spacing(2),
    gap: Theme.spacing(4),
    [Theme.media.down('small')]: {
      marginTop: Theme.spacing(3),
      gap: Theme.spacing(4),
    },
  },
  iconsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: Theme.spacing(5),

    [Theme.media.down('small')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  iconWrapper: {
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifySelf: 'center',

    p: {
      ...textObject.p3,
      marginTop: Theme.spacing(3),
    },
  },
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: Theme.colors.greyscale4,
    marginTop: Theme.spacing(2),
    marginBottom: Theme.spacing(2),
  },
  imageWrapperContainer: {
    [Theme.media.down('mid')]: {
      flexDirection: 'column-reverse',
    },
  },
  imageWrapper: {
    width: '55%',
    maxHeight: '80vh',
    objectFit: 'contain',
    marginRight: Theme.spacing(4),
  },
  imageWrapperMobile: {
    maxHeight: '66vh',
    width: '100%',
    alignSelf: 'center',
    objectFit: 'contain',
  },
  disconnectedWrapper: {
    gap: Theme.spacing(4),
    [Theme.media.down('small')]: {
      flexDirection: 'column',
      gap: Theme.spacing(2),
      marginTop: 0,

    },
  },
}

export default TriggersPage
